'use client';
import routes from '@/routes';
import { motion } from 'framer-motion';
import { SignInResponse, signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { FcGoogle } from 'react-icons/fc';
import Button from './index';

const O2google = () => {
  const router = useRouter();

  const handleClick = useCallback(async () => {
    const response: SignInResponse | undefined = await signIn('google');

    if (response?.error) {
      router.push(routes.pages.login({ error: response?.error }));
      return;
    }

    router.push(routes.pages.login());

    return;
  }, [router]);

  return (
    <motion.div
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      className="duration-100"
    >
      <Button
        onClick={handleClick}
        className="!text-p1 bg-content text-secondary rounded-lg shadow-border relative w-full md:mt-5 md:mb-4 md:py-2.5 min-h-0 h-fit lg:mt-6 lg:mb-5 xl:mt-8 xl:mb-6 hover:border-gradient"
      >
        <FcGoogle className="md:h-4 md:w-4 lg:h-5 lg:w-5 xl:h-6 xl:w-6 -mr-2" />
        <span className="text-foreground">oogle</span>
      </Button>
    </motion.div>
  );
};

export default O2google;
