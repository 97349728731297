'use client';

import Button from '@/components/buttons';
import InputField from '@/components/inputs/field';
import { loginResponse } from '@/interfaces/users';
import { loginSchemaValidator } from '@/libs/valideModules';
import routes from '@/routes';
import cn from '@/utils/cn';
import merge from 'deepmerge';
import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { signIn, signOut } from 'next-auth/react';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

type FormValues = {
  email: string;
  password: string;
};

const LoginForm = ({
  getResponse,
  captchaKey,
  logOut,
}: {
  getResponse: loginResponse | null;
  captchaKey: string;
  logOut: boolean;
}) => {
  const { theme } = useTheme();
  const [loginProps, setLoginProps] = useState<loginResponse | null>(
    getResponse,
  );
  const router = useRouter();

  const initialValues = useMemo(() => {
    return {
      email: '',
      password: '',
    };
  }, []);
  const reRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    if (logOut) {
      signOut({ redirect: false });
    }
  }, [logOut]);

  const handleSubmit = useCallback(
    async (FormValues: FormValues) => {
      const token: string | null =
        (await reRef.current?.executeAsync()) || null;
      if (!token) {
        return setLoginProps({
          msg: 'Une erreur est survenue. Veuillez actualiser votre page et réessayer.',
          success: false,
        });
      }
      const LoginValues = merge(FormValues, { token });

      const response = await signIn('credentials', {
        ...LoginValues,
        redirect: false,
      });
      reRef.current?.reset();

      if (response?.error) {
        return setLoginProps({ msg: response.error, success: false });
      }

      router.replace(routes.pages.home());
      return;
    },
    [router],
  );

  return (
    <>
      <ReCAPTCHA
        theme={theme as 'dark' | 'light'}
        sitekey={captchaKey}
        size="invisible"
        ref={(ref) => (reRef.current = ref)}
      />
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        className="w-full duration-100"
      >
        {loginProps && (
          <p
            className={cn(
              'text-center rounded-md p-2 mb-3 lg:mb-4 xl:my-5 mt-1.5 lg:mt-2 xl:mt-2.5 !text-h4',
              loginProps.success
                ? 'bg-successBg text-successTxt'
                : 'bg-errorBg text-errorTxt',
            )}
          >
            {loginProps.msg}
          </p>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={loginSchemaValidator}
        >
          {({ isValid, dirty, isSubmitting }) => (
            <Form className="flex flex-col md:mt-2 lg:mt-3 xl:mt-4">
              <InputField
                type="email"
                name="email"
                label="Email"
                fullWidth
                isClearable
                radius="sm"
                required
                onTouchClass="scale-85 mt-1.5 lg:mt-0.5"
                labelPlacement="outside"
                classNames={{
                  label: '!text-p3 text-foreground/80 overflow-visible',
                  base: 'mb-2.5 lg:mb-3 xl:mb-4 px-0.5 h-fit shadow-border rounded-lg py-0.5 !bg-content hover:border-gradient',
                  input: 'hide-autofill h-full !text-p3 m-0 !border-none',
                  innerWrapper: 'p-0 !bg-content !border-none',
                  inputWrapper:
                    'h-8 lg:h-[2.35rem] xl:h-10 min-h-0 !bg-content m-0 rounded-lg !border-none',
                }}
              />

              <InputField
                type="password"
                name="password"
                label="Password"
                fullWidth
                radius="sm"
                required
                labelPlacement="outside"
                onTouchClass="scale-85 mt-1.5 lg:mt-0.5"
                endContents={[VscEye, VscEyeClosed]}
                classNames={{
                  label: '!text-p3 text-foreground/80 overflow-visible',
                  base: 'mb-2.5 lg:mb-3 xl:mb-4 px-0.5 h-fit shadow-border rounded-lg py-0.5 !bg-content hover:border-gradient',
                  input: 'hide-autofill h-full !text-p3 m-0 !border-none',
                  innerWrapper: 'p-0 !bg-content !border-none',
                  inputWrapper:
                    'h-8 lg:h-[2.35rem] xl:h-10 min-h-0 !bg-content m-0 rounded-lg !border-none',
                  endContents: [
                    'text-foreground/90 md:h-6 lg:h-7',
                    'text-foreground/90 md:h-7 lg:h-7',
                  ],
                }}
              />
              <div className="w-full text-h4 text-right mb mb-4 xl:mb-6">
                <Link
                  href={routes.pages.password()}
                  className="w-fit text-asset hover:text-secondary"
                >
                  Mot de passe oublié ?
                </Link>
              </div>

              <Button
                className={cn(
                  '!text-p1 text-white relative w-full min-h-0 h-fit py-2 bg-primary rounded-lg border-1 border-border',
                  {
                    'bg-gradient-to-tr from-secondary font-medium to-special opacity-100 border-2 border-primary':
                      dirty && isValid,
                  },
                )}
                type="submit"
                disabled={!(dirty && isValid)}
                isLoading={isSubmitting}
              >
                Se connecter
              </Button>
            </Form>
          )}
        </Formik>
      </motion.div>
    </>
  );
};

export default LoginForm;
